import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  valueBoxSubtitle: 'Through years of experience in e-commerce and software development we make you fit for the next step.',
  valueBoxTitle1: 'Strategy',
  valueBoxTitle2: 'Research & Analysis',
  valueBoxTitle3: 'Design & Prototyping',
  strapiTitle: 'We save time, you save money',
  strapiText: 'Over the years we have encountered new challenges. We have understood industries and target groups, analysed needs and have thus been able to constantly optimize our own processes. Through our own frameworks and best practices we are able to pass on a significant quality and cost advantage to our customers.{br}{br}Consistency, quality, speed, voice and outstanding user experience. That is our credo. Our promise.',
  heroTitle: 'Customer loyalty through innovative <b>user experience</b>',
  heroSubtitle: 'The customer is king. This statement also applies to digital products. Whether B2C or B2B software. Make sure that the users of your product feel comfortable.',
  descriptionText: 'The user interface is the first figurehead and at the same time one of the most important components of your application. No matter how well thought out and potentially successful a business model or workflow may be. If the user does not know what to do or processes seem too tedious, he gets out. This is of course the worst case in e-commerce. But a good user experience also makes all the difference when dealing with software professionally. A well thought-out user experience concept can significantly improve productivity and conversion rates.{br}{br}Unfortunately, far too little attention is still paid to this issue. As a result, potentials remain unused, processes are frustrating and lengthy, and not to forget, cash money is lying around.{br}{br}Your customers and employees do not want websites or tools. They want user experiences.{br}{br}A well-conceived user experience helps to achieve this. It is the scientific part of the design process. Data and facts are based on years of experience. Measurability is therefore also a top priority. Testing and analysis already in the development process is continued after the launch through A/B tests, user tests and evaluation of analytical data in order to continuously develop and optimize your product.',
  descriptionTitle: 'The user experience decides on the success or failure of a product<b>.</b>',
  contactTitle: 'Use your potential through perfect <b>user experience</b>',
  contactSubtitle: 'Don not sleep your chances away.Take advantage of our experience to better sell your product or to optimize internal processes',
  contactheadline: 'Get <b>free advice</b> on user experience now',
  benefitsText: 'For years we have been dealing with the topic of user interface and user experience design. As experts in the field, we have identified the following advantages of modern UI/UX design:',
  benefitsTitle: 'The main advantages of a modern UI/UX design<b>.</b>',
  benefitsItemA: 'Increase of user satisfaction and user motivation.',
  benefitsItemB: 'Acquiring new customers and building up a loyal regular user base.',
  benefitsItemC: 'Customer loyalty through innovative and modern product design.',
  benefitsItemD: 'Economic success and sales increase through user-friendly interfaces.',
  benefitsItemE: 'Increased quality and consistency through the use of design systems.',
  benefitsItemF: 'Competitive advantages through simplified processes.',
  benefitsItemG: 'Reduced costs through training, customer support and last but not least through simple A/B testing',
  benefitsItemH: 'Process optimization in the professional environment and increase of the "Joy of use".',
  productValues1A: 'Development and analysis of business strategies',
  productValues1B: 'Development of business goals',
  productValues1C: 'Stakeholder & user interviews',
  productValues1D: 'Digital process optimization',
  productValues2A: 'Development of user journeys',
  productValues2B: 'Development of personas',
  productValues2C: 'Carrying out competitor analyses',
  productValues2D: 'Target group analysis',
  productValues3A: 'Creation of customized layouts',
  productValues3B: 'Creation of wireframes',
  productValues3C: 'Creation of interactive prototypes',
  productValues3D: 'Creation of powerful design systems'
})
